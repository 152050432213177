import { render, staticRenderFns } from "./LightTablePagination.vue?vue&type=template&id=41a1cf8c"
import script from "./LightTablePagination.vue?vue&type=script&lang=js"
export * from "./LightTablePagination.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VCard,VCardText,VImg,VList,VListItem,VListItemAvatar,VListItemContent,VListItemTitle,VPagination,VProgressLinear,VSimpleTable,VTooltip})
